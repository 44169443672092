import { createAction, createReducer } from '@reduxjs/toolkit';

import * as types from '../constants/ActionTypes';

export interface IExperiments {
  [key: string]: string;
}

const init: IExperiments = {};

export const setExperimentBranch = createAction<{
  name: string;
  branch: string;
}>(types.SET_EXPERIMENT_BRANCH);

const experiments = createReducer(init, (builder) => {
  builder.addCase(setExperimentBranch, (state, { payload }) => ({
    ...state,
    [payload.name]: payload.branch,
  }));
});

export default experiments;
