import { globalConfig } from '@airslate/platform-config';

const isLocal = typeof __LOCAL__ !== 'undefined' && __LOCAL__;

const config = {
  supervisor: globalConfig.API_SV_HOST,
  supportBackend: globalConfig.API_SV_SUPPORT_BACKEND,
  socketServer: globalConfig.API_SV_SOCKET_HOST,
  pusherKey: globalConfig.API_SV_PUSHER_KEY,
  wssPort: '',
  authEndpoint: '',
  screenShareUrl: globalConfig.API_SV_SCREEN_SHARE_URL,
};

const configEnvs = {
  local: {
    supervisor: 'https://api.usrsprt-rc.xyz',
    supportBackend: 'https://support-backend.usrsprt-rc.xyz',
    socketServer: 'https://api.usrsprt-rc.xyz',
    pusherKey: 'supervisor',
    screenShareUrl: 'https://localhost:8083',
  },
  dev: {
    supervisor: 'https://api-bumblebee.usrsprt.xyz',
    supportBackend: 'https://support-backend-bumblebee.usrsprt.xyz',
    socketServer: 'https://api-bumblebee.usrsprt.xyz',
    pusherKey: 'supervisor',
    screenShareUrl: 'https://screenshare-optimus.usrsprt.xyz',
  },
  rc: {
    supervisor: 'https://api.usrsprt-rc.xyz',
    supportBackend: 'https://support-backend.usrsprt-rc.xyz',
    socketServer: 'https://api.usrsprt-rc.xyz',
    pusherKey: 'supervisor',
    screenShareUrl: 'https://screenshare.usrsprt-rc.xyz',
  },
  hf: {
    supervisor: 'https://api-bumblebee.usrsprt.xyz',
    supportBackend: 'https://support-backend-bumblebee.usrsprt.xyz',
    socketServer: 'https://api-bumblebee.usrsprt.xyz',
    pusherKey: 'supervisor',
  },
  prod: {
    supervisor: 'https://api.usrsprt.com',
    supportBackend: 'https://support-backend.usrsprt.com',
    socketServer: 'https://api.usrsprt.com',
    pusherKey: 'romario',
    screenShareUrl: 'https://screenshare.usrsprt.com',
  },
};

const { origin } = global.location;

const regexForAirslate = /^(https|http):\/\/(.*?)\.(airslate)(-\w*)?\.(com|xyz)/;
const isAirslate = origin.match(regexForAirslate);

const currentEnv = config;
let confKey = 'prod';

if (isAirslate && isAirslate.length && isAirslate.length >= 6) {
  const [, , , , sufix, subdomain] = isAirslate;
  const isProd = !sufix && subdomain === 'com';
  const isRc = sufix && sufix.indexOf('-rc') >= 0 && subdomain === 'xyz';
  const isDev =
    sufix && (sufix.indexOf('-dev') >= 0 || sufix.indexOf('-stage') >= 0) && subdomain === 'xyz';
  const isHf = sufix && sufix.indexOf('-hf') >= 0 && subdomain === 'xyz';

  if (!isProd && (isRc || isDev || isHf)) {
    switch (true) {
      case isRc:
        confKey = 'rc';
        break;
      case isDev:
        confKey = 'dev';
        break;
      case isHf:
        confKey = 'hf';
        break;
      default:
        confKey = 'rc';
    }
  }
} else if (isLocal) {
  confKey = 'local';
} else {
  confKey = 'dev';
}

Object.entries(currentEnv).forEach(([key, value]) => {
  if (!value) {
    currentEnv[key] = configEnvs[confKey][key];
  }
});

// eslint-disable-next-line import/no-mutable-exports
export const { supportBackend, screenShareUrl } = currentEnv;

export const { supervisor, socketServer, pusherKey, wssPort, authEndpoint } = currentEnv;

export const acceptedFileTypes = {
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.ms-excel': [],
  'application/vnd.ms-powerpoint': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
  'application/pdf': [],
  'image/png': [],
  'image/jpeg': [],
  'image/jpg': [],
  'text/plain': [],
};

export const ticketSupportPrefix = {
  signnow: 'webapp/support',
  airslate: 'help-center/',
};

export const salesCompanyConfig = {
  airslate: {
    Interested_In_Product__c: '01t1I000004ZwzT',
    Lead_Source_Detail__c: 'airSlate_Live_Chat',
  },
  whitepaper: {
    Interested_In_Product__c: '01t1I000004ZwzT',
    Lead_Source_Detail__c: 'airslate livechat on whitepapers page',
    Lead_Record_type: '0121I000000AMQDQA4',
    LeadSource: 'Livechat',
  },
};

export const onlineTimeout = 3000;

export const userName = {
  characters: 30,
  words: 3,
};

export const closeChatTimeout = 2000;

export const screenTimeout = 1000;

export const chatCheckIntervalTime = 60 * 1000;

export const chatOfflineMessage = 'Chat is currently offline';
export const maxSymbolsCount = 1000;
