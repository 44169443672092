import { supportBackend } from '../config';
import { getHeaders } from '../utils';

const getExperiment = (experiment: string, userId: string | number) =>
  fetch(`${supportBackend}/support/airslate/fortune/${experiment}?user_id=${userId}`, {
    headers: getHeaders(),
  }).then((res) => res.json());

const trackEvent = (
  event: string,
  data: {
    guest_id: string | number;
    tags?: {
      experiment: string;
    };
  },
) =>
  fetch(`${supportBackend}/support/airslate/track-event/${event}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).then((res) => res.json());

export default {
  getExperiment,
  trackEvent,
};
