import { createAction, createReducer } from '@reduxjs/toolkit';

import * as types from '../constants/ActionTypes';
import * as pageTypes from '../constants/PageTypes';
import Storage from '../services/storage';

const isOpenChatConst = Storage.get('chat-opened-window') === '1';

export interface IChat {
  id: number | null;
  room: string;
  hash: string;
  queue: number;
  typing: boolean;
  closed: boolean;
  loading: boolean;
  page: string;
  sendMail: null;
  mail: null;
  category: null;
  categories: [];
  status: null | boolean;
  showSubCategories: boolean;
  company: number | string;
  screenShare: number;
  screenShoot: boolean;
  message: string;
  onlineStatus: null | boolean;
  initialCategory: null;
  ticket: null | {
    id: number;
    hash: string;
  };
  isTabActive: boolean;
  isRated: boolean;
  savedHistory: boolean;
  nameAsked: boolean;
  waitMessageShowed: boolean;
  formMessage: string;
  isOpenChat: boolean;
  hasUnread: boolean;
  isTyping: boolean;
  openTime: number | null;
  subject: string;
  showPremiumBanner: boolean;
  isMaintenance: boolean;
}

export const initialState: IChat = {
  id: 0,
  room: '',
  hash: '',
  queue: 0,
  typing: false,
  closed: false,
  loading: true,
  page: pageTypes.LOADING,
  sendMail: null,
  mail: null,
  category: null,
  categories: [],
  status: null,
  showSubCategories: false,
  company: 1,
  screenShare: 0,
  screenShoot: false,
  subject: '',
  message: '',
  onlineStatus: null,
  initialCategory: null,
  ticket: null,
  isTabActive: false,
  isRated: false,
  savedHistory: false,
  nameAsked: false,
  waitMessageShowed: false,
  formMessage: '',
  isOpenChat: !!isOpenChatConst,
  hasUnread: false,
  isTyping: false,
  openTime: null,
  showPremiumBanner: false,
  isMaintenance: false,
};

export const setChatRoom = createAction<string>(types.SET_CHAT_ROOM);

export const setChatId = createAction(types.SET_CHAT_ID, (payload: number | null) => {
  Storage.set('chat-id', String(payload));
  return { payload };
});

export const setQueue = createAction<number>(types.SET_QUEUE);
export const setChatLoading = createAction<boolean>(types.SET_LOADING);
export const setHash = createAction<string>(types.SET_HASH);
export const setOperatorTyping = createAction<boolean>(types.SET_OPERATOR_TYPING);
export const setCategory = createAction<null>(types.SET_CATEGORY);
export const setChatStatus = createAction<null | boolean>(types.SET_CHAT_STATUS);
export const setChatCompany = createAction<string | number>(types.SET_COMPANY_ID);
export const setScreenShare = createAction<number>(types.SET_SCREEN_SHARE);
export const setTicketMessage = createAction<string>(types.SET_TICKET_MESSAGE);
export const setTicketSubject = createAction<string>(types.SET_TICKET_SUBJECT);
export const setOnline = createAction<boolean>(types.SET_ONLINE_STATUS);
export const setMaintenance = createAction<boolean>(types.SET_MAINTENANCE_MODE);
export const setTicketId = createAction<null | {
  id: number;
  hash: string;
}>(types.SET_TICKET_ID);
export const setChatRated = createAction<boolean>(types.SET_CHAT_RATE);
export const setNameAsked = createAction<boolean>(types.SET_NAME_ASKED);
export const setWaitMessageDisplay = createAction<boolean>(types.SET_WAIT_MESSAGE_DISPLAY);
export const updateMessageForm = createAction<string>(types.SET_MESSAGE_FORM);
export const chatOpenChange = createAction<boolean>(types.SET_CHAT_OPEN);
export const chatUnreadSet = createAction<boolean>(types.SET_CHAT_UNREAD);
export const chatTypingSet = createAction<boolean>(types.SET_CHAT_TYPING);
export const setChatOpenTime = createAction<number | null>(types.SET_CHAT_OPEN_TIME);
export const showPremiumBanner = createAction<boolean>(types.SHOW_PREMIUM_BANNER);

const chat = createReducer(initialState, (builder) => {
  builder
    .addCase(setChatRoom, (state, { payload }) => ({ ...state, room: payload }))
    .addCase(setChatId, (state, { payload }) => ({ ...state, id: payload }))
    .addCase(setQueue, (state, { payload }) => ({ ...state, queue: payload }))
    .addCase(setChatLoading, (state, { payload }) => ({ ...state, loading: payload }))
    .addCase(setHash, (state, { payload }) => ({ ...state, hash: payload }))
    .addCase(setOperatorTyping, (state, { payload }) => ({ ...state, typing: payload }))
    .addCase(setCategory, (state, { payload }) => ({ ...state, category: payload }))
    .addCase(setChatStatus, (state, { payload }) => ({ ...state, status: payload }))
    .addCase(setChatCompany, (state, { payload }) => ({ ...state, company: payload }))
    .addCase(setScreenShare, (state, { payload }) => ({ ...state, screenShare: payload }))
    .addCase(setTicketMessage, (state, { payload }) => ({ ...state, message: payload }))
    .addCase(setTicketSubject, (state, { payload }) => ({ ...state, subject: payload }))
    .addCase(setOnline, (state, { payload }) => ({ ...state, onlineStatus: payload }))
    .addCase(setMaintenance, (state, { payload }) => ({ ...state, isMaintenance: payload }))
    .addCase(setTicketId, (state, { payload }) => ({ ...state, ticket: payload }))
    .addCase(setChatRated, (state, { payload }) => ({ ...state, isRated: payload }))
    .addCase(setNameAsked, (state, { payload }) => ({ ...state, nameAsked: payload }))
    .addCase(setWaitMessageDisplay, (state, { payload }) => ({
      ...state,
      waitMessageShowed: payload,
    }))
    .addCase(updateMessageForm, (state, { payload }) => ({ ...state, formMessage: payload }))
    .addCase(chatOpenChange, (state, { payload }) => ({ ...state, isOpenChat: payload }))
    .addCase(chatUnreadSet, (state, { payload }) => ({ ...state, hasUnread: payload }))
    .addCase(chatTypingSet, (state, { payload }) => ({ ...state, isTyping: payload }))
    .addCase(setChatOpenTime, (state, { payload }) => ({ ...state, openTime: payload }))
    .addCase(showPremiumBanner, (state, { payload }) => ({ ...state, showPremiumBanner: payload }));
});

export default chat;
