import { reducers as modalReducers } from 'redux-modal-dispatcher';
import { combineReducers } from '@reduxjs/toolkit';
import chat from './chat';
import user from './user';
import landing from './landingPage';
import experiments from './experiments';

const rootReducer = combineReducers({
  ...modalReducers,
  user,
  landing,
  chat,
  experiments,
});

export default rootReducer;
