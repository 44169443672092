import Storage from './services/storage';
import { LastReadMessageKey } from './constants/common';

const clearStorage = ({
  without,
  only,
}: {
  without?: string | string[];
  only?: string | string[];
} = {}) => {
  let listOfKeys = [
    'state',
    'uuid',
    'tmp-user-name',
    'user-id',
    'chat-app-from',
    'com-type',
    'user-support-id',
    'user-support-email',
    'stream-started',
    'screenshare-start-time',
    'screehshoot-active',
    'initial-info',
    'was-opened',
    'chat-opened-window',
    LastReadMessageKey,
  ];

  if (without) {
    if (typeof without === 'string') {
      listOfKeys = listOfKeys.filter((el) => el !== without);
    } else {
      listOfKeys = listOfKeys.filter((el) => without.indexOf(el) === -1);
    }
  }

  if (only) {
    if (typeof only === 'string') {
      listOfKeys = listOfKeys.filter((el) => el === only);
    } else {
      listOfKeys = listOfKeys.filter((el) => only.indexOf(el) !== -1);
    }
  }

  Storage.remove(listOfKeys);
};

const nl2br = (text: string) => text && text.replace(/(?:\r\n|\r|\n)/g, '<br />');

export const html2Send = (text: string) =>
  text && text.replace(/<br\s*\/>|<br\s*>/g, '\n').replace(/<.*?>/g, '');

export const getHeaders = (headers: { Authorization?: string } = {}) => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  ...headers,
});

export { clearStorage, nl2br };
