import React, { Suspense, lazy } from 'react';
import { TranslationsProvider } from '@airslate/platform-locales';

const App = lazy(() =>
  import(/* webpackChunkName: "support-chat" */ '../../containers/desktop/App'),
);

const LazyApp = (props) => (
  <Suspense fallback={<div></div>}>
    <TranslationsProvider namespaces="supportChat">
      {/* eslint-disable-next-line */}
      <App {...props} />
    </TranslationsProvider>
  </Suspense>
);

export default LazyApp;
