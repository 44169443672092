import React from 'react';
import { Provider } from 'react-redux';

import LazyApp from './LazyApp';
import configureStore from '../../../store/configureStore';

const store = configureStore();

const Root = (props) => (
  <Provider store={store}>
    {/* eslint-disable-next-line */}
    <LazyApp {...props} />
  </Provider>
);

export default Root;
