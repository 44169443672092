export type GoogleTagManagerEventData = {
  event?: 'CustomEvent' | string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  [key: string]: any;
};

export default ({
  event = 'CustomEvent',
  eventCategory = 'Support Portal',
  eventAction,
  dataEventLabel,
}: GoogleTagManagerEventData) => {
  // @ts-ignore
  global.dataLayer = global.dataLayer || [];
  // @ts-ignore
  global.dataLayer.push({
    event,
    dataEventLabel,
    eventCategory,
    eventAction,
  });
};
