import { token } from '@airslate/platform-auth';

import { supportBackend } from '../config';
import { getHeaders } from '../utils';

interface IRateSurveyParams {
  organization_id?: string;
  comment?: string;
  hash: string;
  score: number;
}

const rateSurvey = (chatId: number, data: IRateSurveyParams) => {
  const headers = getHeaders();

  const userToken = token.getTokenSync(false);

  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return fetch(`${supportBackend}/support/airslate/ces/chat/${chatId}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers,
  }).then((res) => res.json());
};

export default {
  rateSurvey,
};
