import { chatMountingBusHandler } from '../src/helpers/bus';
import { CHAT_APP_ROOT_ID } from '../src/constants/common';
import { renderChatApp } from '../src/chatRendering';

const CHAT_APP_RENDER_ID = 'support-chat';

const initChatRendering = ({ props = {} } = {}) => {
  const { history, store } = props;

  const rootElement = document.getElementById(CHAT_APP_ROOT_ID);

  if (rootElement) {
    renderChatApp(rootElement, history, store);
  }

  chatMountingBusHandler.on(renderChatApp);
};

if (window.isLanding) {
  initChatRendering();
} else {
  window[`render.${CHAT_APP_RENDER_ID}`] = initChatRendering;
}
