import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'browser-cookies';
import getCookiePath from 'airslate-core-components/src/utils/cookie';
import Loading from 'airslate-redux-core/src/loading';
import User from 'airslate-redux-core/src/user';
import { token } from '@airslate/platform-auth';

import UserTutorialActionItem from 'ui-components/src/components/UserTutorialActionItem';
import TooltipWrap from 'ui-components/src/components/Tooltip/TooltipWrap';
import isEqual from 'lodash/isEqual';
import { connectGlobalStore } from '@airslate/global-store';
import supportChat from './supportChat';
import Storage from './services/storage';
import { $c } from './appConfig';
import Root from './as/components/desktop/Root';
import TooltipContent from './as/components/desktop/TooltipContent';
import addEventToDataLayer from './helpers/addEventToDataLayer';
import { chatTooltipToggleBusHandler } from './helpers/bus';

const chatTooltipWaitTime = 15000; // 15 sec
let timeoutShow = null;

const isChatOpened = () => Storage.get('chat-opened-window') === '1';

const globalState2Props = (state, { user }) => {
  const { id, email, currentOrganization, firstName, lastName } = User.getUser(state);
  return {
    userLoading: Loading.isTaskLoading(state, Loading.ME),
    user: global.isLanding ? user : { id, email, currentOrganization, firstName, lastName },
  };
};

const chatTooltipCookie = 'support-chat-tooltip-close';
const getTokenNumber = () => {
  const authToken = token.getTokenSync(false);

  return (
    authToken
      ? authToken
          .split('')
          .map((el) => el.charCodeAt())
          .reduce((ac, el) => ac + el, 0)
      : 1
  ).toString();
};

const NewChatSection = ({ user, userLoading }) => {
  const [chatTooltip, setTooltip] = useState(false);
  const [isTooltipInited, setInitedTooltip] = useState(false);
  const [prevState, setPrevState] = useState({});
  const [showTooltip, setShowTooltip] = useState(true);

  const chatRef = useRef(null);

  const stopToolpip = useCallback(() => {
    clearTimeout(timeoutShow);

    setTooltip(false);
    Cookies.set(chatTooltipCookie, getTokenNumber(), getCookiePath());
  }, []);

  const closeChatTooltip = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      addEventToDataLayer({ eventAction: 'Close tooltip', dataEventLabel: 'Chat' });

      stopToolpip();
    },
    [stopToolpip],
  );

  const handleOpenChat = useCallback(() => {
    stopToolpip();
    if (supportChat && supportChat.startLoad) {
      supportChat.startLoad({}, false);
    }
  }, [stopToolpip]);

  const initChatTooltip = useCallback(() => {
    if (isTooltipInited) {
      setTooltip(true);
      return;
    }

    const tooltipWasStarted = Cookies.get(chatTooltipCookie);

    if (tooltipWasStarted) {
      if (tooltipWasStarted === getTokenNumber()) return;

      Cookies.erase(chatTooltipCookie, getCookiePath());
    }

    clearTimeout(timeoutShow);

    timeoutShow = setTimeout(() => {
      const overlay = document.querySelectorAll('aside.page-overlay');

      if (!isChatOpened() && !overlay.length) {
        const hideTooltip = global.supportChat?.showTooltip === false;

        setTooltip(hideTooltip ? false : showTooltip);
        setInitedTooltip(true);
      }
    }, chatTooltipWaitTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTooltip]);

  useEffect(() => {
    const toggleTooltipCallback = (value) => {
      setShowTooltip(value);
    };

    chatTooltipToggleBusHandler.on(toggleTooltipCallback);

    return () => {
      chatTooltipToggleBusHandler.off(toggleTooltipCallback);
    };
  }, []);

  useEffect(() => {
    if (showTooltip && !isChatOpened()) {
      initChatTooltip();
    } else {
      stopToolpip();
    }
  }, [showTooltip, initChatTooltip, stopToolpip]);

  useEffect(() => {
    if (!isEqual({ userLoading, user }, prevState)) {
      if (!userLoading) {
        supportChat.init({ user });
      }

      setPrevState({ userLoading, user });
    }
  }, [userLoading, user, prevState]);

  useEffect(() => {
    if (window.isLanding) return;

    const app = document.getElementById('spa-root');
    let mainContainer = null;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (!mutation.addedNodes.length) {
          return;
        }

        mutation.addedNodes.forEach(({ className, tagName }) => {
          if (tagName === 'ASIDE' && className.includes('page-overlay') && showTooltip) {
            setTooltip(false);
          }
        });
      });
    });

    Array.from(app?.children)?.forEach((item) => {
      if (item.className === 'sl-grid__wrap' && !mainContainer) {
        mainContainer = item;
      }
    });

    if (mainContainer) {
      observer.observe(mainContainer, { childList: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [showTooltip, stopToolpip]);

  return !userLoading ? (
    <UserTutorialActionItem>
      <TooltipWrap
        content={
          <TooltipContent
            onClick={handleOpenChat}
            message={$c('SUPPORT_CHAT_TOOLTIP_TEXT')}
            closeLabel={$c('SUPPORT_CHAT_TOOLTIP_CLOSE_LABEL')}
            onClose={closeChatTooltip}
          />
        }
        childrenRef={chatRef}
        place="top-left"
        theme="light"
        boxShadow
        withInteraction
        hideOnScroll={false}
        withMouseEvent={false}
        isHidden={!chatTooltip}
        className="sl-tooltip--width-min-xm sl-tooltip--indents-md"
      >
        <div style={{ height: '40px' }} onClick={stopToolpip} ref={chatRef}>
          <Root user={user} />
        </div>
      </TooltipWrap>
    </UserTutorialActionItem>
  ) : null;
};

NewChatSection.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    currentOrganization: PropTypes.string,
    autoGenerated: PropTypes.bool,
    meta: PropTypes.shape({
      isUserReady: PropTypes.bool,
    }),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  userLoading: PropTypes.bool,
};

NewChatSection.defaultProps = {
  userLoading: false,
};

export default connectGlobalStore(globalState2Props)(NewChatSection);
