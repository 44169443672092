import { supportBackend } from '../config';
import { getHeaders } from '../utils';

const getChatSettings = () =>
  fetch(`${supportBackend}/support/airslate/chats/settings`, {
    headers: getHeaders(),
  }).then((res) => res.json());

export default {
  getChatSettings,
};
