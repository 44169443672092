/* eslint-disable camelcase */
import { v4 as uuidV4 } from 'uuid';
import experiments from './experiments';
import settings from './settings';
import { getHeaders } from '../utils';

import { supervisor, supportBackend } from '../config';
import Storage from '../services/storage';
import { getBrowserVersion, getPlatform } from '../helpers/deviceHelpers';
import { getUserID } from '../helpers/userGuestId';
import { IChat } from './types/chat';
import { IMessage } from './types/message';
import getChatCompany from '../helpers/getChatCompany';
import survey from './survey';

const {
  location: { href },
} = global;
const browser = getBrowserVersion(true);

const errorHandler = (err: Error) => {
  // eslint-disable-next-line no-console
  console.error(err);
};

const getURLSearchParams = (params: { [key: string]: any }) =>
  new URLSearchParams(
    Object.entries(params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      // eslint-disable-next-line no-param-reassign
      acc[key] = String(value);
      return acc;
    }, {}),
  );

export const checkBan = (data: {
  email?: string;
}): Promise<{
  ban: boolean;
}> =>
  fetch(`${supervisor}/api/chat/checkForBan`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).then((res) => res.json());

const getFingerprint = () => {
  const fp = Storage.get('fp');

  return fp ? fp : uuidV4();
};

const fetchChat = (
  userId: null | string,
  userName: string,
  category: null | string,
  company: number | string,
  currentOrganization: string,
  userToken: string | null,
  addData: {
    name?: string;
    email?: string;
    init_message?: string;
    welcome_message?: string;
  } = {},
): Promise<IChat> => {
  const uuid = Storage.get('uuid');
  const expGuestId = getUserID();

  const fp = getFingerprint();

  const data = {
    user_id: userId,
    user_name: userName,
    category_id: category,
    company: getChatCompany(true),
    browser,
    os: getPlatform(),
    fp,
    referer: href,
    org_id: currentOrganization,
    exp_guest_id: expGuestId,
    ...(uuid && { uuid }),
    ...addData,
  };

  const headers = getHeaders();

  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return fetch(`${supervisor}/api/chat/new`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((err) => {
      errorHandler(err);
    });
};

const sendMessage = (data: {
  chat_id: number;
  hash: string;
  message: string;
  user_id: null | string;
  user_type: string;
  uuid: string;
}): Promise<{ error?: string; success?: string }> =>
  fetch(`${supervisor}/api/chat/new-message`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((err) => {
      errorHandler(err);
    });

const updateCallback = (
  chatId: number,
  hash: string,
  data: {
    id: number;
    messageId: number;
    answer: boolean;
    selectedDuration?: number;
  },
) => {
  const updatedFields: {
    answer: boolean;
    selectedDuration?: number;
  } = {
    answer: data.answer,
  };

  if (data.selectedDuration) {
    updatedFields.selectedDuration = data.selectedDuration;
  }

  return fetch(`${supervisor}/api/chat/callback/${data.id}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify({ chat_id: chatId, hash, ...updatedFields }),
  })
    .then((res) => res.json())
    .catch((err) => {
      errorHandler(err);
    });
};

const loadCategories = (data: { with_integrations: number }) =>
  fetch(`${supervisor}/api/chat/categories?${getURLSearchParams(data)}`, {
    headers: getHeaders(),
  })
    .then((res) => res.json())
    .catch((err) => {
      errorHandler(err);
    });

const checkChat = (
  userId: null | string,
  userName: string,
): Promise<{
  status: boolean;
}> => {
  const fp = getFingerprint();
  const uuid = Storage.get('uuid');
  const chatId = Storage.get('chat-id');

  const data = {
    user_id: userId,
    user_name: userName,
    ...(uuid && { uuid }),
    company: getChatCompany(true),
    chat_id: chatId,
    fp,
  };

  return fetch(`${supervisor}/api/chat/check?${getURLSearchParams(data)}`, {
    headers: getHeaders(),
  })
    .then((res) => res.json())
    .catch((err) => {
      errorHandler(err);
      return {
        status: false,
      };
    });
};

const sendAttachment = (
  chatId: number,
  hash: string,
  uuid: string,
  files: File[],
  fromLanding: boolean,
) => {
  const formData = new FormData();
  formData.append('chat_id', String(chatId));
  formData.append('hash', hash);
  formData.append('uuid', uuid);
  files.forEach((file) => formData.append(file.name, file));

  const req = fetch(`${supervisor}/api/chat/new-attachment`, {
    method: 'POST',
    body: formData,
  });

  if (fromLanding) {
    return req;
  }

  return req.catch((err) => {
    errorHandler(err);
  });
};

const sendCloseChat = (userId: null | string, chatId: number, hash: string, data = {}) =>
  fetch(`${supervisor}/api/chat/new/close`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      ...data,
      user_id: userId,
      chat_id: chatId,
      hash,
    }),
  })
    .then((res) => res.json())
    .catch((err) => {
      errorHandler(err);
    });

const sendChatLocation = (chatId: number, hash: string, data = {}) =>
  fetch(`${supervisor}/api/chat/${chatId}/location`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      ...data,
      hash,
    }),
  })
    .then((res) => res.json())
    .catch((err) => {
      errorHandler(err);
    });

const sendActivity = (data: { activity: string; chat_id: number; hash: string; path: string }) =>
  fetch(`${supervisor}/api/chat/new-activity`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).catch((err) => {
    errorHandler(err);
  });

interface ICreateTicketData {
  category_id: null;
  domain: string;
  email: string;
  exp_guest_id: string;
  id: null | string;
  message: string;
  org_id: string;
  subject: string;
}

const createTicket = (
  data: ICreateTicketData,
  company: string | number,
  token: null | string | number,
): Promise<{
  id: number;
  hash: string;
}> => {
  const headers = getHeaders();

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return fetch(`${supportBackend}/support/${company}/ticket`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        errorHandler(res.errors);
      }

      return res;
    })
    .catch((err) => {
      errorHandler(err);
    });
};

export interface ISalesData {
  Phone?: string;
  Name?: string;
  LastName?: string;
  Email?: string;
  airslate?: {
    Interested_In_Product__c: string;
    Lead_Source_Detail__c: string;
  };
  whitepaper?: {
    Interested_In_Product__c: string;
    Lead_Source_Detail__c: string;
    Lead_Record_type: string;
    LeadSource: string;
  };
  Company?: string;
  HTTP_Referer_URL__c?: string;
  Live_Chat_user_ID__c?: string;
}

const sendUserForm = (data: ISalesData) => {
  const fp = getFingerprint();
  const dataToSend = { ...data, Live_Chat_user_ID__c: `${fp}_${data.Live_Chat_user_ID__c}` };

  return fetch(`${supportBackend}/api/contact-sales/send-sales-form`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(dataToSend),
  }).then((res) => res.json());
};

const updateUserName = (uuid: string, name: string) =>
  fetch(`${supervisor}/api/chat/update-name`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify({ uuid, name }),
  }).then((res) => res.json());

export const readMessages = (data: { chat_id: number; hash: string; message_id: number }) =>
  fetch(`${supervisor}/api/chat/read-messages?${getURLSearchParams(data)}`, {
    headers: getHeaders(),
  }).then((res) => res.json());

const checkLastMessage = (data: {
  chat_id: number;
  hash: string;
  message_id: number;
}): Promise<{
  latest: boolean;
  messages: IMessage[];
  status: number;
}> =>
  fetch(`${supervisor}/api/chat/verify-latest-message?${getURLSearchParams(data)}`, {
    headers: getHeaders(),
  })
    .then((res) => res.json())
    .catch((err) => {
      errorHandler(err);
      return {
        status: false,
      };
    });

const sendUserPremium = (data: {
  comment: string;
  email: string;
  firstname: string;
  lastname: string;
}) =>
  fetch(`${supportBackend}/support/airslate/premium-support`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).then((res) => res.json());

const screenShareJoinClient = (data: { id: number | null; hash: string }) =>
  fetch(`${supervisor}/api/v3/screen-sharing/chat/${data.id}/join-client?hash=${data.hash}`, {
    method: 'POST',
    headers: getHeaders(),
  }).then((res) => res.json());

export default {
  fetchChat,
  sendMessage,
  updateCallback,
  sendCloseChat,
  sendAttachment,
  sendActivity,
  loadCategories,
  checkChat,
  sendUserForm,
  createTicket,
  updateUserName,
  checkBan,
  sendChatLocation,
  readMessages,
  checkLastMessage,
  sendUserPremium,
  screenShareJoinClient,

  settings,
  experiments,
  survey,
};
