import { createAction, createReducer } from '@reduxjs/toolkit';
import * as types from '../constants/ActionTypes';

export interface IUser {
  name: string;
  id: null | string;
  currentOrganization: string;
  type: string;
  token: null | string;
  firstName: string;
  lastName: string;
}

export const initialState: IUser = {
  name: '',
  id: null,
  currentOrganization: '',
  type: 'unregistered',
  token: null,
  firstName: '',
  lastName: '',
};

export const setUserName = createAction<string>(types.SET_USER_NAME);
export const setUserInfo = createAction<IUser>(types.SET_USER_INFO);
export const setUserType = createAction<string>(types.SET_USER_TYPE);
export const setUserId = createAction<string>(types.SET_USER_ID);
export const setUserOrganization = createAction<string>(types.SET_USER_ORGANIZATION);

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUserName, (state, { payload }) => ({ ...state, name: payload }))
    .addCase(setUserInfo, (state, { payload }) => ({ ...state, ...payload }))
    .addCase(setUserType, (state, { payload }) => ({ ...state, type: payload }))
    .addCase(setUserId, (state, { payload }) => ({ ...state, id: payload }))
    .addCase(setUserOrganization, (state, { payload }) => ({
      ...state,
      currentOrganization: payload,
    }));
});

export default userReducer;
