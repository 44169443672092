import cookies from 'browser-cookies';
import { safeLocalStorage } from '@airslate/platform-utils';
import getCookiePath from 'airslate-core-components/src/utils/cookie';

const Storage = () => {
  const storage = safeLocalStorage;
  const defaultPrefix = 'chat-app-';

  return {
    set(key, value, newPrefix) {
      const prefix = newPrefix || newPrefix === '' ? newPrefix : defaultPrefix;
      storage.setItem(`${prefix}${key}`, value);
      cookies.set(`${prefix}${key}`, value.toString(), { ...getCookiePath() });
    },

    remove(key, newPrefix) {
      const prefix = newPrefix || newPrefix === '' ? newPrefix : defaultPrefix;

      if (Array.isArray(key)) {
        key.forEach((el) => {
          storage.removeItem(`${prefix}${el}`);
          cookies.erase(`${prefix}${el}`, { ...getCookiePath() });
        });
      } else {
        storage.removeItem(`${prefix}${key}`);
        const initialDate = 'Thu, 01 Jan 1970 00:00:01 GMT';

        cookies.set(`${prefix}${key}`, '', { ...getCookiePath(), expires: initialDate });
      }
    },
    get(key, newPrefix) {
      const prefix = newPrefix || newPrefix === '' ? newPrefix : defaultPrefix;
      return cookies.get(`${prefix}${key}`) || storage.getItem(`${prefix}${key}`) || null;
    },
  };
};

export default new Storage();
