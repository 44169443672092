import React from 'react';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import { historyBackDecorator } from '@airslate/platform-history';
import { globalStore, GlobalStore } from '@airslate/global-store';
import createHistory from 'airslate-navigation/src/create-history';

import { CHAT_APP_ROOT_ID } from '../src/constants/common';
import App from '../src/App';

export let domNode: HTMLElement | null = null;

export const renderChatApp = (element: HTMLElement, history: History, store: GlobalStore) => {
  const rootElement = document.getElementById(CHAT_APP_ROOT_ID);
  domNode = element || rootElement;

  ReactDOM.render(
    <App
      store={store || globalStore}
      history={history || historyBackDecorator(createHistory(globalStore))}
    />,
    domNode,
  );
};

export const unmountChatApp = () => domNode && unmountComponentAtNode(domNode);
