const load = () => import(/* webpackChunkName: "support-as-chat" */ './index');

const supportChat = {
  init: (options) => {
    load().then((instance) => instance.init(options));
  },
  startLoad: (options = {}) => {
    load().then((instance) => instance.startLoad(options));
  },
};

global.supportChat = { ...(global.supportChat || {}), ...supportChat };

export default supportChat;
