import React, { Suspense } from 'react';
import LoaderWrapper from 'ui-components/src/components/LoaderWrapper';

import { Switch, Route } from '@airslate/platform-router';
import routes from '@airslate/front-routes';

import SupportChatButton from './SupportChatButton';
import { unmountChatApp } from './chatRendering';

const SupportChat = (props: { history: History }) => (
  <Suspense fallback={<LoaderWrapper flexible />}>
    <SupportChatButton history={props.history} />
  </Suspense>
);

const pathes = [
  routes.creator.addonEditor,
  routes.creator.addons,
  routes.diagram.$root,
  routes.login,
  routes.dashboardAnalytics,
  routes.dashboardHome,
  routes.creator.packets,
  routes.configureMetric,
  routes.configureWidget,
  routes.createWidget,
  routes.support.topic,
  routes.support.articlesCategory,
  routes.support.articles,
  routes.support.community,
  routes.support.chatHistory,
  routes.support.ticket,
  routes.support.tickets,
  routes.support.search,
  routes.support.faqCategory,
  routes.support.faqs,
  routes.support.releaseNote,
  routes.support.releaseNotes,
  routes.support.$root,
  routes.dashboard,
];

const Routes = () => (
  <Switch>
    <Route path={pathes} component={SupportChat} />
    <Route
      path="*"
      component={() => {
        unmountChatApp();
        return null;
      }}
    />
  </Switch>
);

export default Routes;
