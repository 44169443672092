import { createAction, createReducer } from '@reduxjs/toolkit';

import * as types from '../constants/ActionTypes';

export interface IAttachment {
  extension: string;
  link: string;
  mime: string;
  name: string;
  size: string;
}

interface ICallBack {
  id: number;
  created_at: string;
  updated_at: string;
  message_id: number;
  type: string;
  answer: null;
  valid_to: null;
  deleted_at: null;
  title: string;
  text: string;
  durability: number[];
}

export interface IMessage {
  objectType: string;
  id: number | string;
  message: string;
  authorable_id: number;
  authorable_type: string;
  author_name: string;
  authorName: string;
  type: string;
  created_at: string;
  author_type: string;
  authorType: string;
  attachments: IAttachment[];
  callback: null | ICallBack;
  isStaff: boolean;
  createdAt: string;
  isBot: boolean;
  ticket: null | {
    id: number;
    hash: string;
  };
  disabled: boolean;
  formItems: {
    type: string;
    name: string;
    text?: string;
    placeholder?: string;
    autoFocus?: boolean;
    required?: boolean;
    validator?: boolean | (() => void);
    onKeyDown?: () => void;
    onPaste?: () => void;
    default?: unknown;
  }[];
  submitButton: string;
  cancelButton: string;
  cancelAction: () => void;
  onSubmit: (data: {
    phone: string;
    name: string;
    email: string;
    Phone?: string;
    Email?: string;
    CompanySize__c?: {
      value: unknown;
    };
    emailLabel?: string;
    message: string;
    messageLabel?: string;
    subject: string;
    comment?: string;
  }) => void;
  children: JSX.Element;
  actions: {
    id: number;
    action: () => void;
    title: string;
  }[];
  img: string | null;
  isScrollTo?: boolean;
  withoutScroll?: boolean;
  title: string;
  icon: string;
  className: string;
  allowTitle: string;
  cancelTitle: string;
  action: (answer: unknown) => void;
  onClick: (rate: string) => void;
  files: File[];
  uuid: number | string;
  chatId: number;
  tempFromForm: boolean;
  status: string;
  authorableType: string;
  options: {
    id: number;
    action: string;
    title: string;
    eventType: string;
  }[];
  messageId?: number;
  headline?: string;
  parameters?: {
    translation: { key?: string };
  };
}

type TOperator = null | {
  name: string;
  img?: string | null;
};

type TMessage = Partial<IMessage>;

export interface ILandingPage {
  messages: TMessage[];
  isClosed: boolean;
  operator: TOperator;
  isOnline: boolean;
  showCloseModal: boolean;
  isBanned: boolean;
  onlineStatus: boolean;
}

const initLanding: ILandingPage = {
  messages: [],
  isClosed: false,
  operator: null,
  isOnline: true,
  showCloseModal: false,
  isBanned: false,
  onlineStatus: false,
};

export const addMessage = createAction<TMessage>(types.ADD_LANDING_MESSAGE);
export const updateMessage = createAction<TMessage>(types.UPDATE_LANDING_MESSAGE);
export const setMessages = createAction<TMessage[]>(types.SET_LANDING_MESSAGES);
export const rmLandingMessage = createAction<number | string>(types.REMOVE_LANDING_MESSAGE);
export const rmMessageByType = createAction<string>(types.REMOVE_LANDING_MESSAGE_BY_TYPE);
export const setIsClosed = createAction<boolean>(types.SET_LANDING_IS_CLOSED);
export const setOperator = createAction<TOperator>(types.SET_OPERATOR);
export const addOperator = createAction<TOperator>(types.ADD_OPERATOR);
export const setInternetConnection = createAction<boolean>(types.INTERNET_CONNECTION);
export const setModalState = createAction<boolean>(types.SHOW_CLOSE_MODAL);
export const setChatUserBanned = createAction<boolean>(types.SET_CHAT_BANNED_PAGE);
export const removeTempMessages = createAction<void>(types.REMOVE_TEMP_MESSAGES);

const landing = createReducer(initLanding, (builder) => {
  builder
    .addCase(addMessage, (state, { payload }) => {
      const isMessageExists =
        (payload.uuid && state.messages.some((el) => el.uuid === payload.uuid)) ||
        (payload.id && state.messages.some((el) => el.id === payload.id));

      return {
        ...state,
        messages: isMessageExists
          ? state.messages.map((el) =>
              (payload.uuid && el.uuid === payload.uuid) || (payload.id && el.id === payload.id)
                ? payload
                : el,
            )
          : [...state.messages, payload],
      };
    })
    .addCase(updateMessage, (state, { payload }) => {
      const { messages } = state;

      return {
        ...state,
        messages: messages.map((msg) =>
          payload.uuid && msg.uuid === payload.uuid ? { ...msg, ...payload } : msg,
        ),
      };
    })
    .addCase(setMessages, (state, { payload }) => ({
      ...state,
      messages: [...payload],
    }))
    .addCase(rmLandingMessage, (state, { payload }) => ({
      ...state,
      messages: state.messages.filter((el) => {
        if (el.id) return el.id !== payload;
        if (el.uuid) return el.uuid !== payload;

        return true;
      }),
    }))
    .addCase(rmMessageByType, (state, { payload }) => ({
      ...state,
      messages: state.messages.filter((el) => el.objectType !== payload),
    }))
    .addCase(setIsClosed, (state, { payload }) => ({
      ...state,
      isClosed: payload,
    }))
    .addCase(setOperator, (state, { payload }) => ({
      ...state,
      operator: payload,
    }))
    .addCase(setInternetConnection, (state, { payload }) => ({
      ...state,
      isOnline: payload,
    }))
    .addCase(setModalState, (state, { payload }) => ({
      ...state,
      showCloseModal: payload,
    }))
    .addCase(setChatUserBanned, (state, { payload }) => ({
      ...state,
      isBanned: payload,
    }))
    .addCase(removeTempMessages, (state) => ({
      ...state,
      messages: state.messages.filter((el) => !el.tempFromForm || el.status === 'notSend'),
    }));
});

export default landing;
