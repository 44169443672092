export const ADD_MESSAGE = 'ADD_MESSAGE';
export const SET_MESSAGES = 'SET_MESSAGES';
export const UPDATE_MESSAGE_SEND_STATUS = 'UPDATE_MESSAGE_SEND_STATUS';
export const UPDATE_CALLBACK_SEND_STATUS = 'UPDATE_CALLBACK_SEND_STATUS';
export const UPDATE_MESSAGE_CALLBACK = 'UPDATE_MESSAGE_CALLBACK';
export const SET_CHAT_ROOM = 'SET_CHAT_ROOM';
export const SET_CHAT_ID = 'SET_CHAT_ID';
export const SET_USER_NAME = 'SET_USER_NAME';
export const SET_USER_ORGANIZATION = 'SET_USER_ORGANIZATION';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_CHAT_STATUS = 'SET_CHAT_STATUS';
export const SET_WELCOME = 'SET_CHAT_WELCOME_PAGE';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_LOADING = 'SET_LOADING';
export const SET_QUEUE = 'SET_QUEUE';
export const CLOSE_CHAT = 'CLOSE_CHAT';
export const SET_HASH = 'SET_HASH';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_OPERATOR_TYPING = 'SET_OPERATOR_TYPING';
export const SET_PAGE_TYPE = 'SET_PAGE_TYPE';
export const SET_MODAL_DATA = 'SET_MODAL_DATA';
export const TOGGLE_SEND_MAIL = 'TOGGLE_SEND_MAIL';
export const SET_MAIL = 'SET_MAIL';
export const SET_SUB_CATEGORIES = 'SET_SUB_CATEGORIES';
export const SET_COMPANY_ID = 'SET_COMPANY_ID';
export const SET_SCREEN_SHARE = 'SET_SCREEN_SHARE';
export const SET_SCREEN_SHOOT = 'SET_SCREEN_SHOOT';
export const SET_TICKET_MESSAGE = 'SET_TICKET_MESSAGE';
export const SET_TICKET_SUBJECT = 'SET_TICKET_SUBJECT';
export const SET_ONLINE_STATUS = 'SET_ONLINE_STATUS';
export const SET_MAINTENANCE_MODE = 'SET_MAINTENANCE_MODE';
export const SET_INITIAL_CATEGORY = 'SET_INITIAL_CATEGORY';
export const SET_TICKET_ID = 'SET_TICKET_ID';
export const SHOW_PREMIUM_BANNER = 'SHOW_PREMIUM_BANNER';

export const ADD_LANDING_MESSAGE = 'ADD_LANDING_MESSAGE';
export const REMOVE_LANDING_MESSAGE = 'REMOVE_LANDING_MESSAGE';
export const SET_LANDING_MESSAGES = 'SET_LANDING_MESSAGES';
export const SET_LANDING_IS_CLOSED = 'SET_LANDING_IS_CLOSED';
export const REMOVE_LANDING_MESSAGE_BY_TYPE = 'REMOVE_LANDING_MESSAGE_BY_TYPE';
export const SET_OPERATOR = 'SET_OPERATOR';
export const ADD_OPERATOR = 'ADD_OPERATOR';
export const USE_IN_LANDING_MENU = 'USE_IN_LANDING_MENU';
export const INTERNET_CONNECTION = 'INTERNET_CONNECTION';
export const SHOW_CLOSE_MODAL = 'SHOW_CLOSE_MODAL';
export const SET_TAB_ACTIVE = 'SET_TAB_ACTIVE';
export const SET_CHAT_RATE = 'SET_CHAT_RATE';
export const SET_CHAT_SAVED = 'SET_CHAT_SAVED';
export const SET_NAME_ASKED = 'SET_NAME_ASKED';
export const UPDATE_LANDING_MESSAGE = 'UPDATE_LANDING_MESSAGE';
export const SET_WAIT_MESSAGE_DISPLAY = 'SET_WAIT_MESSAGE_DISPLAY';
export const SET_MESSAGE_FORM = 'SET_MESSAGE_FORM';
export const SET_CHAT_BANNED_PAGE = 'SET_CHAT_BANNED_PAGE';

export const SET_CHAT_OPEN = 'SET_CHAT_OPEN';
export const SET_CHAT_UNREAD = 'SET_CHAT_UNREAD';
export const SET_CHAT_TYPING = 'SET_CHAT_TYPING';

// statistic
export const SET_STATISTIC_INFO = 'SET_STATISTIC_INFO';
export const SET_STATISTIC_SESSION = 'SET_STATISTIC_SESSION';

export const SET_STATISTIC_ACTIONS = 'SET_STATISTIC_ACTIONS';
export const SET_EXPERIMENT_BRANCH = 'SET_EXPERIMENT_BRANCH';
export const SET_CHAT_OPEN_TIME = 'SET_CHAT_OPEN_TIME';

// messages
export const REMOVE_TEMP_MESSAGES = 'REMOVE_TEMP_MESSAGES';
