import Bowser from 'bowser';

const { userAgent } = window.navigator;

export const getBrowser = (noLowerCase) => {
  const browsers = ['Edge', 'Chrome', 'Firefox', 'Safari', 'Opera', 'AOL', 'Netscape', 'MSIE'];
  const browser = browsers.find((item) => userAgent.includes(item)) || 'other';

  if (noLowerCase) return browser;

  return browser.toLowerCase();
};

export const getPlatformInfo = () => Bowser.parse(window.navigator.userAgent) || {};

export const getBrowserVersion = (withName) => {
  const browser = getBrowser(true);

  if (browser === 'other') return withName ? browser : null;

  const versionRegex = new RegExp(`${browser}\\/((\\d|\\.)+)`);
  const versionMatch = userAgent.match(versionRegex);
  const version = versionMatch && versionMatch[1];

  return withName ? `${browser} ${version}` : version;
};

export const isMobile = () =>
  !!userAgent.match(/webOS|iPhone|iPod|BlackBerry|Windows Phone/i) ||
  (!!userAgent.match(/Android/i) && !!userAgent.match(/Mobile/i));

export const isTablet = () => {
  const tabletRegex = new RegExp(
    '(ipad|tablet|(android(?!.*mobile))' +
      '|(windows(?!.*phone)(.*touch))' +
      '|kindle|playbook|silk' +
      '|(puffin(?!.*(IP|AP|WP))))',
    'g',
  );

  return tabletRegex.test(userAgent.toLowerCase());
};

// todo similar to getPlatform, check use of getPlatform
export const getOsVersion = () => {};

export const getPlatform = () => {
  const userPlatformBlockRegex = /;.*?\)/;
  const userAgentPlatformBlock = userAgent.match(userPlatformBlockRegex)[0];
  const userAgentPlatform = userAgentPlatformBlock.replace(/ /g, '').toLowerCase();
  const plarforms = [
    'windows10',
    'windows7',
    'windows8',
    'otherwindows',
    'macos',
    'android',
    'linux',
  ];
  const mobilePlatforms = ['ios', 'android'];

  if (userAgent.includes('iPhone')) return 'ios-mobile';

  if (userAgent.toLowerCase().includes('mobile')) {
    const mobilePlatform = mobilePlatforms.find((item) => userAgentPlatform.includes(item));

    return mobilePlatform ? `${mobilePlatform}-mobile` : 'other-mobile';
  }

  return plarforms.find((item) => userAgentPlatform.includes(item)) || 'other';
};

export const getDevice = () => {
  if (isMobile()) return 'mobile';
  if (isTablet()) return 'tablet';

  return 'desktop';
};

export default {
  isMobile,
  isTablet,
  getBrowser,
  getDevice,
  getPlatform,
  getPlatformInfo,
};
