import React from 'react';
import PropTypes from 'prop-types';
import ActionsGroup from 'ui-components/src/components/ActionsGroup';
import ActionsGroupItem from 'ui-components/src/components/ActionsGroupItem';
import NoticeUnified from 'ui-components/src/components/Notice/NoticeUnified';
import Button from 'ui-components/src/components/Button';
import CloseIcon from 'airslate-static.icons/src/16/close-medium.svg';

const TooltipContent = ({ onClick, onClose, message, closeLabel }) => (
  <div onClick={onClick}>
    <NoticeUnified
      message={message}
      color="light"
      fullWidth
      rounded
      className="tooltip-notice tooltip-notice--text-sm"
    >
      <ActionsGroup>
        <ActionsGroupItem>
          <Button
            icon={CloseIcon}
            size="xxs"
            label={closeLabel}
            empty
            mode=""
            widthAuto
            onClick={onClose}
          />
        </ActionsGroupItem>
      </ActionsGroup>
    </NoticeUnified>
  </div>
);

TooltipContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  message: PropTypes.string,
  closeLabel: PropTypes.string,
};

TooltipContent.defaultProps = {
  message: '',
  closeLabel: '',
  onClick: () => {},
};

export default TooltipContent;
