import { bus } from '@airslate/platform-bus';

export interface BusMethods {
  on: (fn: any) => void;
  off: (fn: any) => void;
}

export const createBusHandlers = (eventName: string): BusMethods => ({
  on: (fn) => {
    bus.on(`${eventName}`, fn);
  },
  off: (fn) => {
    bus.off(`${eventName}`, fn);
  },
});

export const chatTooltipToggleBusHandler = createBusHandlers('support-chat-tooltip:toggle');
export const chatMountingBusHandler = createBusHandlers('support-chat:mount');
export const chatUnmountingBusHandler = createBusHandlers('support-chat:unmount');
