import React, { FC, Fragment } from 'react';
import { History } from 'history';
import api from 'airslate-api-client';

import { TranslationsConnection } from '@airslate/platform-locales';
import { ProviderGlobalStore, GlobalStore } from '@airslate/global-store';
import { Router } from '@airslate/platform-router';
import { setAPI } from '@airslate/platform-api';

import Routes from './Routes';
import SupportChatButton from './SupportChatButton';

setAPI(api);

interface IProps {
  history: History;
  store: GlobalStore;
}

let user: {
  id?: string;
  email?: string;
} = {};

//@ts-ignore
if (global.user && global.user.data && global.isLanding) {
  user = {
    //@ts-ignore
    id: global.user.data.id,
    //@ts-ignore
    email: global.user.data.attributes.email,
  };
}

const App: FC<IProps> = ({ history, store }) => {
  return (
    <TranslationsConnection platform="desktop">
      <ProviderGlobalStore store={store} disabledInitForRoutes={['/']} history={history}>
        <Fragment key="PlatformModals">
          {window.isLanding ? (
            <SupportChatButton user={user} />
          ) : (
            <Router history={history}>
              <Routes />
            </Router>
          )}
        </Fragment>
      </ProviderGlobalStore>
    </TranslationsConnection>
  );
};

export default App;
